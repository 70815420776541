
    import { Vue } from "vue-class-component"
    // eslint-disable-next-line
    import { Container, RecordReceived, ShareRecordReceived, _CloudKit } from "./cloudkit.d"
    import { fetchRawMeetings } from "./scripts/cloudkitHelper"

    const environments = {
        dev: {
            apiToken: "453d6a5e2c46a7fe4d7f1e7f4b3646d2e142bd229b47cafb7d0d4ee615167a07",
            name: "development"
        },
        prod: {
            apiToken: "9a226ba0f6c64670559eb97b5f495b992ba0503d8191b3a3034838686ef3c43d",
            name: "production"
        }
    }

    export default class App extends Vue {
        loggedIn = false

        meetings?: RecordReceived[] | ShareRecordReceived[]
        container?: Container

        usedEnv = process.env.NODE_ENV === "development" ? environments.dev : environments.prod

        version = "0.1"

        async mounted() {
            window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", () => {
                this.setColorScheme()
            })
            this.setColorScheme()

            await this.cloudkitSetup()
            if (this.loggedIn) {
                // @ts-ignore
                this.$router.push("/gallery")
            } else {
                // @ts-ignore
                this.$router.push("/")
            }
        }

        setColorScheme() {
            if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
                // darkmode
                document.body.classList.add("darkmode")
                document.body.classList.remove("lightmode")
            } else {
                // lightmode
                document.body.classList.remove("darkmode")
                document.body.classList.add("lightmode")
            }
        }

        async cloudkitSetup() {
            // eslint-disable-next-line
            const cloudKit = CloudKit.configure({
                containers: [
                    {
                        containerIdentifier: "iCloud.de.universegame.confy",
                        apiTokenAuth: {
                            apiToken: this.usedEnv.apiToken,
                            persist: true,
                            signInButton: {
                                theme: "black"
                            },
                            signOutButton: {
                                theme: "black"
                            }
                        },
                        environment: this.usedEnv.name
                    }
                ]
            })
            this.container = cloudKit.getDefaultContainer()
            this.container.whenUserSignsIn().then((userInfo: any) => {
                this.onLogin(userInfo)
            })
            await this.container.setUpAuth()
        }

        async onLogin(userInfo: any) {
            this.loggedIn = userInfo != undefined
            if (this.loggedIn) {
                // @ts-ignore
                window.location = "/#/gallery"
            }
            this.meetings = await fetchRawMeetings(this.container)
            this.$forceUpdate()
            console.log(userInfo)
            console.log(userInfo.userRecordName)
            console.log(this.meetings)
        }

        async update() {
            this.meetings = await fetchRawMeetings(this.container)
            this.$forceUpdate()
        }

        get showSignInSignOut() {
            return true
        }

        get bugreportLink() {
            return "https://universegame.de/bug?app=confy&v=Webapp+" + this.version
        }
    }
