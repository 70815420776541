import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import Home from "../views/Home.vue"

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/gallery",
        name: "Gallery",
        component: () => import("../views/Gallery.vue")
    }
]

const router = createRouter({
    history: createWebHistory("/#"),
    routes
})

export default router
