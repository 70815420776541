<template>
    <div class="home">
        <img id="logo" alt="ConfMan logo" src="../assets/mac1024.png" />
        <h1>ConfMan</h1>
        <p>Manage your Videoconferences across all your Apple Devices</p>
        <a href="https://apps.apple.com/de/app/confman/id1611137426" target="_blank" id="appstore">
            <img src="../assets/download_on_appstore.svg" />
        </a>

        <div class="spacer10"></div>

        <p>
            Online Meetings get more important every day, the pandemic only increased the transition speed like
            gasoline. Many of us have dozen of Online Meetings. Some store the access Data in small text files or add
            them to their Calendar events, but there is no organized, beautiful and synchronized way of combining the
            access data to all sorts of Meeting Tools in one App. That's what this app offers. Save your various
            Meetings in this app and it will be synchronized across all your devices and, with a simple click, you join
            them as easily as ever.
        </p>

        <div class="spacer10"></div>

        <p>Or use the online version of the app now, just login with your Apple-ID down below</p>
        <small
            >Only the app itself is currently localized and available in German and English, the online Version is
            currently only accessible in English</small
        ><br />
    </div>
</template>

<script>
    // @ is an alias to /src
    import { Options, Vue } from "vue-class-component"

    @Options({
        components: {}
    })
    export default class Home extends Vue {}
</script>

<style scoped>
    .home {
        display: flexbox;
        justify-content: center;
        text-align: center;
    }
    .home > * {
        margin: auto;
    }

    #logo {
        width: 20ch;
    }

    #appstore > img {
        width: 20ch;
        margin: 1ch;
    }

    p {
        max-width: 70vw;
    }
</style>
